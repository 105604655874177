import "../css/partners-style.css";
import ImperiumEstate from "../images/partners/imperium-estate.png";
import DortemProtiRakovine from "../images/partners/dortem-proti-rakovine.jpeg";
import SAM from "../images/partners/sam.png";
import ThaiDiamonds from "../images/partners/thai-diamonds.png";
import PatiPati from "../images/partners/pati-pati.png";

export const Partners = () => {
    return (
        <div className="logos" id="partneri">
            <div className="logos-slide">
                <img alt="1" src={ImperiumEstate} />
                <img alt="2" src={DortemProtiRakovine} />
                <img alt="3" src={SAM} />
                <img alt="4" src={ThaiDiamonds} />
                <img alt="5" src={PatiPati} />
            </div>
            <div className="logos-slide">
                <img alt="1" src={ImperiumEstate} />
                <img alt="2" src={DortemProtiRakovine} />
                <img alt="3" src={SAM} />
                <img alt="4" src={ThaiDiamonds} />
                <img alt="5" src={PatiPati} />
            </div>
            <div className="logos-slide">
                <img alt="1" src={ImperiumEstate} />
                <img alt="2" src={DortemProtiRakovine} />
                <img alt="3" src={SAM} />
                <img alt="4" src={ThaiDiamonds} />
                <img alt="5" src={PatiPati} />
            </div>
        </div>
    );
};
