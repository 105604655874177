import React from 'react';
import styled from 'styled-components';
import {Link as RouterLink} from "react-router-dom";
import AboutUsImage from '../images/about-us.png';

const Section = styled.section`
  user-select: none;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1095px) {
    height: 100%;
  }
`;

const Main = styled.section`
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1095px) {
    height: 100%;
  }
`;

const AllText = styled.div`
  width: 600px;
  max-width: 100%;
  padding: 0 10px;
`;

const Image = styled.img`
  width: 800px;
  max-width: 100%;
  height: auto;
  padding: 0 10px;

  @media (max-width: 1516px) {
    width: 600px;
  }

  @media (max-width: 1305px) {
    width: 400px;
  }

  @media (max-width: 1095px) {
    display: none;
  }
`;

const MobileImage = styled.img`
  width: 70%;
  height: auto;
  display: none;

  @media (max-width: 1095px) {
    display: block;
  }
`;

const MobileImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionName = styled.h4`
  font-family: 'Anton', sans-serif;
  font-size: 30px;
  color: #777777;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 15px;
`;

const SectionTitle = styled.h1`
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #777777;
  line-height: 30px;
  margin-bottom: 35px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
  }
`;

const TextBoxLink = styled(RouterLink)`
  color: #000;
  text-decoration: none;
  padding: 10px 20px;
  margin-right: 20px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s;

  &:hover {
    color: #FFF;
    background-color: #000;
    border: 2px solid #000;
  }
`;

const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
        section.scrollIntoView({behavior: 'smooth'});
    }
};

export const AboutUs: React.FC = () => {
    return (
        <Section id={"o-nas"}>
            <Main>
                <Image src={AboutUsImage} alt="Obrázek k sekci o nás"/>
                <AllText>
                    <SectionName>O NÁS</SectionName>
                    <SectionTitle>Dům inovací & inteligence</SectionTitle>
                    <Paragraph>Jsme společností, která spojuje kreativitu a technickou zručnost ve světě stavebnictví a
                        web developmentu. S vášní a pečlivostí přistupujeme ke každému projektu, přinášejíce inovativní
                        a inteligentní řešení pro naše klienty. Naše týmy architektů, inženýrů a vývojářů spolupracují s
                        klienty na každém kroku, aby zajistily, že jejich vize jsou proměněny v realitu. Síla našich
                        stavebních dovedností je doplněna našimi schopnostmi v oblasti web developmentu, čímž
                        poskytujeme komplexní řešení až do posledního detailu. Jsme hrdí na to, že přinášíme kvalitní
                        práci a vytváříme prostředí, ve kterém se naši klienti mohou cítit inspirativně a
                        úspěšně.</Paragraph>
                    <Row>
                        <TextBoxLink to="#nas-tym" onClick={() => scrollToSection('nas-tym')}>Náš tým</TextBoxLink>
                        <TextBoxLink to="#kontakt" onClick={() => scrollToSection('kontakt')}>Spojte se s
                            námi</TextBoxLink>
                    </Row>
                </AllText>
                <MobileImageWrapper>
                    <MobileImage src={AboutUsImage} alt="Obrázek k sekci o nás"/>
                </MobileImageWrapper>
            </Main>
        </Section>
    );
};
