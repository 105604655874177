import React from 'react';
import styled, {keyframes} from 'styled-components';
import Project1 from '../images/projects/1.jpeg';
import Project2 from '../images/projects/2.jpg';
import Project3 from '../images/projects/3.jpg';
import Project4 from '../images/projects/4.jpg';
import Project5 from '../images/projects/5.jpg';
import Project6 from '../images/projects/6.jpg';
import Project7 from '../images/projects/7.jpg';
import Project8 from '../images/projects/8.jpg';
import Project9 from '../images/projects/9.jpg';
import Project10 from '../images/projects/10.jpg';
import Project11 from '../images/projects/11.jpg';
import Project12 from '../images/projects/12.jpg';
import Project13 from '../images/projects/13.jpg';
import Project14 from '../images/projects/14.jpg';
import Project15 from '../images/projects/15.jpg';
import Project16 from '../images/projects/16.jpg';
import Project17 from '../images/projects/17.jpg';
import Project18 from '../images/projects/18.jpg';
import Project19 from '../images/projects/19.jpg';
import Project20 from '../images/projects/20.jpg';
import Project21 from '../images/projects/21.jpg';
import Project22 from '../images/projects/22.jpg';
import Project23 from '../images/projects/23.jpg';
import Project24 from '../images/projects/24.jpg';
import Project25 from '../images/projects/25.jpg';
import Project26 from '../images/projects/26.jpg';
import Project27 from '../images/projects/27.jpg';
import Project28 from '../images/projects/28.jpg';
import Project29 from '../images/projects/29.jpg';
import Project30 from '../images/projects/30.jpg';
import {Link as RouterLink} from "react-router-dom";

const animate = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 100px);
    filter: blur(33px);
  }

  to {
    opacity: 1;
    transform: translate(0);
    filter: blur(0);
  }
`;

const ProjectsSection = styled.section`
  height: 100vh;
  position: relative;
  user-select: none;
  overflow: hidden;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

const Slide = styled.div`

`;

const Item = styled.div`
  width: 200px;
  height: 300px;
  position: absolute;
  top: 70%;
  transform: translate(0, -50%);
  border-radius: 20px;
  background-position: 50% 50%;
  background-size: cover;
  display: inline-block;
  transition: 0.5s;

  &:nth-child(1) {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
    .content {
      display: block;
    }
  }

  &:nth-child(2) {
    left: 50%;
    box-shadow: 0 30px 50px #505050;
    background-size: auto;
  }

  &:nth-child(3) {
    left: calc(50% + 220px);
    box-shadow: 0 30px 50px #505050;
    background-size: auto;
  }

  &:nth-child(4) {
    left: calc(50% + 440px);
    box-shadow: 0 30px 50px #505050;
    background-size: auto;
  }

  &:nth-child(5) {
    left: calc(50% + 660px);
    box-shadow: 0 30px 50px #505050;
    background-size: auto;
  }

  &:nth-child(6) {
    left: calc(50% + 880px);
    box-shadow: 0 30px 50px #505050;
    background-size: auto;
  }

  &:nth-child(7) {
    left: calc(50% + 1100px);
    box-shadow: 0 30px 50px #505050;
    background-size: auto;
  }

  &:nth-child(n + 8) {
    left: calc(50% + 1320px);
    opacity: 0;
    background-size: auto;
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 150px;
    box-shadow: 0 10px 10px #505050 !important;
    top: 75%;

    &:nth-child(2) {
      left: 50%;
      z-index: 10;
      background-size: auto;
    }

    &:nth-child(3) {
      left: calc(50% + 50px) !important;
      z-index: 9;
      background-size: auto;
    }

    &:nth-child(4) {
      left: calc(50% + 100px) !important;
      z-index: 8;
      background-size: auto;
    }

    &:nth-child(5) {
      left: calc(50% + 150px) !important;
      z-index: 7;
      background-size: auto;
    }

    &:nth-child(6) {
      left: calc(50% + 200px) !important;
      z-index: 6;
      background-size: auto;
    }
    
    &:nth-child(7) {
      left: calc(50% + 250px) !important;
      z-index: 6;
      background-size: auto;
    }

    &:nth-child(n + 8) {
      left: calc(50% + 300px) !important;
      opacity: 0;
      background-size: auto;
    }
  }
`;

const ItemContent = styled.div`
  width: 100%;
  height: 30%;
  padding-left: 100px;
  padding-bottom: 30px;
  padding-top: 15px;
  text-align: left;
  color: #eee;
  transform: translate(0, -50%);
  display: none;
  margin-top: 12%;
  background-color: rgba(0, 0, 0, 0.6);

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-top: 5px;
    margin-top: 130px;
    height: 20%;
  }
`;

const ItemContentName = styled.div`
  font-size: 120px;
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0;
  margin-top: 30px;
  animation: ${animate} 1s ease-in-out 1 forwards;

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: 15px;
  }
`;

const ItemContentDescription = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  opacity: 0;
  animation: ${animate} 1s ease-in-out 0.3s 1 forwards;
  font-size: 30px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-right: 15px;
  }
`;

const ItemContentSecondDescription = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 150px;
  opacity: 0;
  animation: ${animate} 1s ease-in-out 0.3s 1 forwards;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-right: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
`;

/*const SeeMoreLink = styled(RouterLink)`
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  margin-right: 20px;
  border: 2px solid #fff;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  opacity: 0;
  animation: ${animate} 1s ease-in-out 0.6s 1 forwards;

  &:hover {
    color: #000;
    background-color: #fff;
    border: 2px solid #fff;
  }
`;*/

const TextBoxLink = styled(RouterLink)`
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  margin-right: 20px;
  border: 2px solid #fff;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s;

  &:hover {
    border: 2px solid #000;
  }
`;

const Buttons = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const Projects: React.FC = () => {

    const onClickNext = () => {
        const items = document.querySelectorAll<HTMLElement>('.item');
        const slide = document.querySelector<HTMLElement>('.slide');
        if (slide) {
            const firstItem = items[0];
            if (firstItem) {
                slide.appendChild(firstItem);
            }
        }
    };

    const onClickPrev = () => {
        const items = document.querySelectorAll<HTMLElement>('.item');
        const slide = document.querySelector<HTMLElement>('.slide');
        if (slide) {
            const lastItem = items[items.length - 1];
            if (lastItem) {
                slide.prepend(lastItem);
            }
        }
    }

    const projects = [
        {
            id: 1,
            name: 'Rezidence Grébovka',
            description: 'Realizace pokládky dlažby na terče. Více než 1800m² položené plochy',
            second_description: 'Realizace projektu proběhla v roce 2020.',
            imageUrl: Project1,
            link: '#' // prvni
        },
        {
            id: 2,
            name: 'Poliklinika I. P. Pavlova',
            description: 'Rozsáhlá rekonstrukce polikliniky IPP / Myslíkova 1O',
            second_description: 'Jeden z dalších projektů pro polikliniky IPP. Tyto projekty jsou jedny z nejnáročnějších a to jednoznačné svou časovou náročností. Na projektech tohoto typu pracujeme na dvousměnný provoz, abychom omezili provoz na pracovišti co nejméně.',
            imageUrl: Project2,
            link: '#' // druhy
        },
        {
            id: 3,
            name: 'Poliklinika I. P. Pavlova',
            description: 'Rozsáhlá rekonstrukce polikliniky IPP / Myslíkova 1O',
            second_description: 'Jeden z dalších projektů pro polikliniky IPP. Tyto projekty jsou jedny z nejnáročnějších a to jednoznačné svou časovou náročností. Na projektech tohoto typu pracujeme na dvousměnný provoz, abychom omezili provoz na pracovišti co nejméně.',
            imageUrl: Project3,
            link: '#' // treti
        },
        {
            id: 4,
            name: 'Poliklinika I. P. Pavlova',
            description: 'Další rekonstrukce polikliniky IPP / Legerova 56',
            second_description: 'Kompletní rekonstrukce posledního patra polikliniky Legerova 56 proběhla pod přísným dohledem za plného provozu kliniky. Rekonstrukce celého patra se za pomocí dvousměnného provozu zrealizovala v rekordním čase za pouhých šest dní.',
            imageUrl: Project4,
            link: '#' //
        },
        {
            id: 5,
            name: 'Akce Petrská 33',
            description: 'Realizace dostavby sedmipodlažní budovy v srdci Prahy, na Florenci',
            second_description: 'Tento činžovní dům jsme realizovali z pozice generálního dodavatele pro českého developera. V tomto domě jsme realizovali 122 bytových jednotek a 800m² sklepní restautace Shell & Core.',
            imageUrl: Project5,
            link: '#' // ctvrty
        },
        {
            id: 6,
            name: 'Akce Petrská 33',
            description: 'Realizace dostavby sedmipodlažní budovy v srdci Prahy, na Florenci',
            second_description: 'Střešní apartmán o rozloze 120m² nedaleko Václavského náměstí.',
            imageUrl: Project6,
            link: '#' //
        },
        {
            id: 7,
            name: 'Akce Petrská 33',
            description: 'Realizace dostavby sedmipodlažní budovy v srdci Prahy, na Florenci',
            second_description: 'Schodiště v budově jsme částečně rekonstruovali broušením kamene a částečně jsme zhotovili nová monolitická ramena.',
            imageUrl: Project7,
            link: '#' //
        },
        {
            id: 8,
            name: 'Akce Petrská 33',
            description: 'Realizace dostavby sedmipodlažní budovy v srdci Prahy, na Florenci',
            second_description: 'Developerským standardem je pískovcová dlažba v základu 60x60 cm spojená s mozaikou ve sprchových koutech.',
            imageUrl: Project8,
            link: '#' //
        },
        {
            id: 9,
            name: 'Akce Petrská 33',
            description: 'Realizace dostavby sedmipodlažní budovy v srdci Prahy, na Florenci',
            second_description: 'Více než 3600m² položené dřevěné podlahy.',
            imageUrl: Project9,
            link: '#' //
        },
        {
            id: 10,
            name: 'Akce Varšavská 12',
            description: 'Rekonstrukce dvou pavilonů rezidence Varšavská 12 Praha Vinohrady',
            second_description: 'Realizace projektu Varšavská 12 byla pod drobnohledem památkové úřadu. Další spolupráce s významným českým developerem.',
            imageUrl: Project10,
            link: '#' //sesty
        },
        {
            id: 11,
            name: 'Akce Varšavská 12',
            description: 'Rekonstrukce dvou pavilonů rezidence Varšavská 12 Praha Vinohrady',
            second_description: 'V těchto objektech jsme v průběhu čtyř měsíců vybudovali na klíč 14 bytových jednotek, pět podkrovních mezonetů a dva komerční prostory a 600m².',
            imageUrl: Project11,
            link: '#' //
        },
        {
            id: 12,
            name: 'Akce Varšavská 12',
            description: 'Rekonstrukce dvou pavilonů rezidence Varšavská 12 Praha Vinohrady',
            second_description: 'Finální pohled na část chodby a schodiště v pavilonu B rezidence Varšavská 12 na Vinohradech.',
            imageUrl: Project12,
            link: '#' //
        },
        {
            id: 13,
            name: 'AKCE VELKÁ CHUCHLE',
            description: 'Revitalizace dostihového závodiště Velká Chuchle. Ve spolupráci s firmou SAM - jízdárny a povrchy',
            second_description: 'V roce 2020 jsme spolupracovali na projektu rekonstrukce Chuchelského závodiště, zejména od mlatových cest, oplocení až po pokládku finánlních povrchů.',
            imageUrl: Project13,
            link: '#' //paty
        },
        {
            id: 14,
            name: 'Akce asfalty Kladno',
            description: 'Realizace asfaltových chodníků a přejezdů v Kladně v Kročehlavech pro společnost Stavby STAFO s.r.o.',
            second_description: '',
            imageUrl: Project14,
            link: '#' //
        },
        {
            id: 15,
            name: 'Akce Střednice 51 & 52',
            description: 'Realizace projektu dvou bytových domů nedaleko města Mělník',
            second_description: 'Kompletní systém zateplení fasády společně se zateplením střechy. Na tento projekt byla čerpána dotace a podíleli jsme se jako genrální dodavatel stavby.',
            imageUrl: Project15,
            link: '#' //
        },
        {
            id: 16,
            name: 'Akce Střednice 51',
            description: 'Realizace projektu dvou bytových domů nedaleko města Mělník',
            second_description: 'Zateplení proběhlo podle norem zelené úspory. Mimo jiné byl použit materiál PIR.',
            imageUrl: Project16,
            link: '#' //
        },
        {
            id: 17,
            name: 'Akce Střednice 51',
            description: 'Realizace projektu dvou bytových domů nedaleko města Mělník',
            second_description: 'Akce byla realizována včetně dodávky klempířkých prvků, foliové střechy a zábradlím dodávaném naším dlouhodobým partnerem v oboru zámečnictví.',
            imageUrl: Project17,
            link: '#' //
        },
        {
            id: 18,
            name: 'Akce Střednice 51',
            description: 'Realizace projektu dvou bytových domů nedaleko města Mělník',
            second_description: 'Výsledek práce na realizaci bytového domu Střednice 51',
            imageUrl: Project18,
            link: '#' //
        },
        {
            id: 19,
            name: 'Akce Střednice 52',
            description: 'Realizace projektu dvou bytových domů nedaleko města Mělník',
            second_description: 'Výsledek práce na realizaci bytového domu Střednice 52.',
            imageUrl: Project19,
            link: '#' //
        },
        {
            id: 20,
            name: 'Akce fasáda Dubí',
            description: 'Realizace fasády pro našeho stáleho klienta na ubytovacím zařízení v Kladně.',
            second_description: 'Na tomto betonovém monolitu byla zhotovena finální fasáda a to včetně klempířských prvků. Děkujeme za dlouhodobě projevenou důvěru. ',
            imageUrl: Project20,
            link: '#' //
        },
        {
            id: 21,
            name: 'Akce fasáda Dubí',
            description: 'Realizace fasády pro našeho stáleho klienta na ubytovacím zařízení v Kladně.',
            second_description: 'Největší odměnou pro nás je, když se na nás klienti opakovaně obrací.',
            imageUrl: Project21,
            link: '#' //
        },
        {
            id: 22,
            name: 'Akce Navrátilova 4',
            description: 'Realizace projektu na klíč',
            second_description: 'Demontáž stávající střechy včetně krovu a následná nadezdívka a montáž krovu včetně položení tašek a klempířských prvků.',
            imageUrl: Project22,
            link: '#' //
        },
        {
            id: 23,
            name: 'Akce Navrátilova 4',
            description: 'Realizace projektu na klíč',
            second_description: 'Realizace projektu pro amerického investora nedaleko Karlova náměstí v Praze.',
            imageUrl: Project23,
            link: '#' //
        },
        {
            id: 24,
            name: 'Akce Navrátilova 4',
            description: 'Realizace projektu na klíč',
            second_description: 'Realizace projektu na adrese jako je Navrátilova 4 je nejsložitější z hlediska zásobování z důvodu špatné dopravní dostupnosti, avšak ani to nás nezastaví.',
            imageUrl: Project24,
            link: '#' //
        },
        {
            id: 25,
            name: 'Akce Strašnice',
            description: 'Pro soukromého investora jsme v této lokalitě provedli kompletní přestavbu čtyř bytů z 2+1 na 3+kk.',
            second_description: '',
            imageUrl: Project25,
            link: '#' //
        },
        {
            id: 26,
            name: 'Akce Strašnice',
            description: 'Pro soukromého investora jsme v této lokalitě provedli kompletní přestavbu čtyř bytů z 2+1 na 3+kk.',
            second_description: 'Posunutím jádra jsme vyřešili problematiku malého koupelnového prostoru.',
            imageUrl: Project26,
            link: '#' //
        },
        {
            id: 27,
            name: 'Akce Strašnice',
            description: 'Pro soukromého investora jsme v této lokalitě provedli kompletní přestavbu čtyř bytů z 2+1 na 3+kk.',
            second_description: 'Přesun kuchyňského kouta byl elegantně vyřešen přečerpávacím zařízením vedeném v sádrokartonovém podhledu.',
            imageUrl: Project27,
            link: '#' //
        },
        {
            id: 28,
            name: 'Akce Horní Bezděkov',
            description: 'Ve zmíněné lokalitě jsme realizovali dokončení dvou staveb',
            second_description: 'Konkrétně realizace teréních úprav, oplocení, opěrných zdí, uložení jímky, pokládka betonové dlažby.',
            imageUrl: Project28,
            link: '#' //
        },
        {
            id: 29,
            name: 'Akce Horní Bezděkov',
            description: 'Ve zmíněné lokalitě jsme realizovali dokončení dvou staveb',
            second_description: 'Pokládka betonové dlažby kolem bazénu a veškerých přístupových cest.',
            imageUrl: Project29,
            link: '#' //
        },
        {
            id: 30,
            name: 'Akce Unhošť',
            description: 'Dokončovací práce kolem novostavby řadového dvojdomu v obci Unhošť nedaleko Prahy',
            second_description: 'Vytvoření zahradní infrastruktury, parkovacích míst, opěrné zdi, oplocení, výstavba betonového plotu a tvarování zahradních prostor.',
            imageUrl: Project30,
            link: '#' //
        }
    ];

    return (
        <ProjectsSection id={"galerie"}>
            <Container>
                <Slide className={'slide'}>

                    {projects.map((item) => (
                        <Item className={'item'} style={{backgroundImage: `url(${item.imageUrl})`}} key={item.id}>
                            <ItemContent className={'content'}>
                                <ItemContentName>{item.name}</ItemContentName>
                                <ItemContentDescription>{item.description}</ItemContentDescription>
                                <ItemContentSecondDescription>{item.second_description}</ItemContentSecondDescription>
                                {/*<Row>
                                    <SeeMoreLink to={item.link}>Zjistit více</SeeMoreLink>
                                </Row>*/}
                            </ItemContent>
                        </Item>
                    ))}

                </Slide>

                <Buttons>
                    <Row>
                        <TextBoxLink to="#" onClick={onClickPrev}>Předchozí</TextBoxLink>
                        <TextBoxLink to="#" onClick={onClickNext}>Další</TextBoxLink>
                    </Row>
                </Buttons>
            </Container>
        </ProjectsSection>
    );
};
