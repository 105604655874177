import React from 'react';
import styled from 'styled-components';
import Luky from '../images/luky.png';
import Fila from '../images/fila.png';
import Rob from '../images/rob.png';
import Ucetni from '../images/ucetni.png';
import { FiPhone, FiMail } from 'react-icons/fi';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    user-select: none;
`;

const QRCodeWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  justify-content: flex-end;
  opacity: 0;
  display: flex;
  width: 100px;
  height: 100px;
  transition-delay: .5s;
  transition: all .6s ease;

    @media (max-width: 768px) {
        display: none;
    }
`;

const SectionName = styled.h4`
    font-family: 'Anton', sans-serif;
    font-size: 100px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; /* Align cards to the top */
`;

const CardLabel = styled.label`
    position: relative;
    width: 80px;
    height: 500px; /* Fixed height for CardLabel */
    border-radius: .75rem;
    background-size: 50%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    margin: 0 10px;
    display: flex;
    align-items: flex-end;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0 5px 15px -5px rgba(255,255,255,0.8);

    @media (max-width: 768px) {
        height: 300px;
        background-size: 80%;
        width: 50px;
        justify-content: center;
        align-items: center;
        margin: 0 3px;
    }
`;

const Row = styled.div`
  color: black;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  margin-left: 15px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const Description = styled.div`
  flex-direction: column;
  overflow: hidden;
  height: 160px;
  width: 520px;
  display: none;
  transform: translateY(10px);
  transition-delay: .3s;
  transition: all .3s ease;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 0;
    width: 200px;
    transform: translateY(50%);
  }
`;

const DescriptionText = styled.div`
  color: black;
  text-transform: uppercase;
  font-weight: bold;

    @media (max-width: 768px) {
        font-size: 10px;
    }
`;

const Title = styled.h4`
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 5px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 12px;
    justify-content: center;
  }
`;

const PhoneIcon = styled(FiPhone)`
  margin-right: 5px;
`;

const MailIcon = styled(FiMail)`
  margin-right: 5px;
`;

const EmailLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${CardLabel} {
    width: 600px;
    cursor: default;
    background-position: 50% 30%;

    @media (max-width: 768px) {
      width: 200px;
      background-position: 50% 10%;
    }
  }

  &:checked + ${CardLabel} ${Description} {
    display: block !important;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      display: flex !important;
    }
  }

  &:checked + ${CardLabel} ${QRCodeWrapper} {
    opacity: 1 !important;
    right: 10px;
  }
`;

export const OurTeam: React.FC = () => {
    const generateQRCodeUrl = (data: string) => {
        return `https://qrcode.tec-it.com/API/QRCode?data=${encodeURIComponent(data)}`;
    };

    return (
        <Wrapper id={"nas-tym"}>
            <SectionName>NÁŠ TÝM</SectionName>
            <Container>
                <Input type="radio" name="slide" id="c1" defaultChecked />
                <CardLabel htmlFor="c1" style={{ backgroundImage: `url(${Luky})` }}>
                    <QRCodeWrapper>
                        <img src={generateQRCodeUrl('MECARD:N:Lukáš Gerhardt;TEL:770668830;EMAIL:gerhardt@amurg.cz')} alt="QR Code" />
                    </QRCodeWrapper>
                    <Row>
                        <Description>
                            <Title>Lukáš Gerhardt</Title>
                            <DescriptionText>CEO</DescriptionText>
                            <ContactInfo>
                                <PhoneIcon />
                                770 668 830
                            </ContactInfo>
                            <ContactInfo>
                                <MailIcon />
                                <EmailLink href="mailto:gerhardt@amurg.cz">gerhardt@amurg.cz</EmailLink>
                            </ContactInfo>
                        </Description>
                    </Row>
                </CardLabel>

                <Input type="radio" name="slide" id="c2" />
                <CardLabel htmlFor="c2" style={{ backgroundImage: `url(${Fila})` }}>
                    <QRCodeWrapper>
                        <img src={generateQRCodeUrl('MECARD:N:Filip Maršík;TEL:601601421;EMAIL:stavbyvedouci@amurg.cz')} alt="QR Code" />
                    </QRCodeWrapper>
                    <Row>
                        <Description>
                            <Title>Filip Maršík</Title>
                            <DescriptionText>Stavbyvedoucí</DescriptionText>
                            <ContactInfo>
                                <PhoneIcon />
                                601 601 421
                            </ContactInfo>
                            <ContactInfo>
                                <MailIcon />
                                <EmailLink href="mailto:stavbyvedouci@amurg.cz">stavbyvedouci@amurg.cz</EmailLink>
                            </ContactInfo>
                        </Description>
                    </Row>
                </CardLabel>

                <Input type="radio" name="slide" id="c3" />
                <CardLabel htmlFor="c3" style={{ backgroundImage: `url(${Rob})` }}>
                    <QRCodeWrapper>
                        <img src={generateQRCodeUrl('MECARD:N:Robin Bezák;EMAIL:it@amurg.cz')} alt="QR Code" />
                    </QRCodeWrapper>
                    <Row>
                        <Description>
                            <Title>Robin Bezák</Title>
                            <DescriptionText>Web Development</DescriptionText>
                            <ContactInfo>
                                <MailIcon />
                                <EmailLink href="mailto:it@amurg.cz">it@amurg.cz</EmailLink>
                            </ContactInfo>
                        </Description>
                    </Row>
                </CardLabel>

                <Input type="radio" name="slide" id="c4" />
                <CardLabel htmlFor="c4" style={{ backgroundImage: `url(${Ucetni})` }}>
                    <QRCodeWrapper>
                        <img src={generateQRCodeUrl('MECARD:N:Účetní AMURG.CZ;EMAIL:ucetni@amurg.cz')} alt="QR Code" />
                    </QRCodeWrapper>
                    <Row>
                        <Description>
                            <Title>Účetní</Title>
                            <ContactInfo>
                                <MailIcon />
                                <EmailLink href="mailto:ucetni@amurg.cz">ucetni@amurg.cz</EmailLink>
                            </ContactInfo>
                        </Description>
                    </Row>
                </CardLabel>
            </Container>
        </Wrapper>
    );
};