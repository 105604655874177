import React, { createContext, useContext, useState } from 'react';

interface LogoAnimationContextType {
    animationEnded: boolean;
    setAnimationEnded: React.Dispatch<React.SetStateAction<boolean>>;
}

const LogoAnimationContext = createContext<LogoAnimationContextType | undefined>(undefined);

export const useLogoAnimationContext = () => {
    const context = useContext(LogoAnimationContext);
    if (!context) {
        throw new Error('useLogoAnimationContext must be used within a LogoAnimationProvider');
    }
    return context;
};

export const LogoAnimationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [animationEnded, setAnimationEnded] = useState(false);

    return (
        <LogoAnimationContext.Provider value={{ animationEnded, setAnimationEnded }}>
            {children}
        </LogoAnimationContext.Provider>
    );
};
