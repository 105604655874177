import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import LogoImage from '../images/logo.svg';
import {Link as RouterLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {ImageSlider} from "./ImageSlider";
import gsap from "gsap";

const HeaderContainer = styled.div`
    position: relative;
    height: 100vh;
    overflow: hidden;
    user-select: none;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    padding: 0 8%;
`;

const Navbar = styled.nav`
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
`;

const Logo = styled.img`
    margin-top: 15px;
    width: 80px;
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 6;
    filter: invert(100%);

    &:hover {
        transform: scale(1.1);
    }
`;

const Ul = styled.ul<{ open: boolean }>`
    text-align: right;
    position: absolute;
    flex-direction: row;
    align-items: center;
    z-index: 5;
    margin: 0;
    right: 8%;
    transition: right 0.3s ease;

    @media (max-width: 1250px) {
        flex-direction: column;
        background: #fff;
        top: -50px;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 25vh 0 0 0;
        right: ${({ open }) => (open ? '0' : '-100%')};
    }
`;

const Li = styled.li`
    display: inline-block;
    list-style: none;
    margin: 10px 30px;

    @media (max-width: 1250px) {
        margin-bottom: 35px;
    }
`;

const Link = styled(RouterLink)`
    color: #fff;
    text-decoration: none;
    position: relative;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
    font-style: normal;
    font-size: 20px;
    text-transform: uppercase;

    &::after {
        content: '';
        width: 0;
        height: 3px;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        transition: width 0.3s;
    }

    &:hover::after {
        width: 50%;
    }

    @media (max-width: 1250px) {
        color: #000;
        &::after {
            background: #000;
        }
    }
`;

const TextBox = styled.div`
    color: #fff;
    position: absolute;
    bottom: 8%;
    z-index: 1;
`;

const Paragraph = styled.p`
    font-size: 50px;
    font-weight: 600;
    position: absolute;
    margin-left: 10px;

    @media (max-width: 1250px) {
        font-size: 36px;
        margin-top: -10px;
        margin-bottom: 0;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 0;
    }
`;

const Heading = styled.h1`
    font-size: 190px;
    line-height: 160px;
    margin-bottom: 10px;

    @media (max-width: 1250px) {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }

    @media (max-width: 768px) {
        font-size: 60px;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const SubHeading = styled.h3`
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 10px;

    @media (max-width: 1250px) {
        font-size: 16px;
        margin-top: -20px;
        margin-bottom: 0;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        margin-top: -40px;
        margin-bottom: 0;
    }
`;

const TextBoxLink = styled(RouterLink)`
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    margin-right: 20px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    margin-bottom: 10px;
    margin-top: 10px;

    &:hover {
        color: #000;
        background-color: #fff;
        border: 2px solid #fff;
    }

    @media (max-width: 768px) {
        font-size: 13px;
        padding: 8px;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
`;

const SocialIcons = styled.div`
    position: absolute;
    right: 5%;
    bottom: 8%;

    @media (max-width: 1250px) {
        right: 25px;
        bottom: 5px;
    }
`;

const SocialIcon = styled.div`
    display: block;
    margin: 25px 5px;

    &:hover {
        transform: scale(1.1);
    }
`;

const Hamburger = styled.div`
    display: none;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 40px;
    z-index: 6;

    @media (max-width: 1250px) {
        display: block;
    }
`;

const HamburgerIcon = styled.div<{ open: boolean }>`
    width: 30px;
    height: 3px;
    background-color: ${({ open }) => (open ? '#000' : '#fff')};
    margin: 8px 0;
    transition: transform 0.3s, opacity 0.3s;
    z-index: 6;

    &:first-child {
        transform: ${({ open }) => (open ? 'rotate(45deg) translate(7px, 8px)' : 'none')};
    }

    &:nth-child(2) {
        opacity: ${({ open }) => (open ? '0' : '1')};
    }

    &:last-child {
        transform: ${({ open }) => (open ? 'rotate(-45deg) translate(7px, -8px)' : 'none')};
    }
`;

export const Header: React.FC = () => {
    const [open, setOpen] = useState(false);
    const ulRef = useRef<HTMLUListElement>(null);
    const logoRef = useRef<HTMLImageElement>(null);

    const toggleMenu = () => {
        setOpen(!open);
        if (ulRef.current && logoRef.current) {
            if (open) {
                gsap.to(ulRef.current, { duration: 0.3, right: '-100%' });
                gsap.to(logoRef.current, { duration: 0.3, x: '-500%', onComplete: () => {
                        gsap.to(logoRef.current, { duration: 0.1, filter: 'invert(100%)', onComplete: () => {
                                gsap.to(logoRef.current, { duration: 0.3, x: 0 });
                            }});
                    }});
            } else {
                gsap.to(ulRef.current, { duration: 0.3, right: 0 });
                gsap.to(logoRef.current, { duration: 0.3, x: '-500%', onComplete: () => {
                        gsap.to(logoRef.current, { duration: 0.1, filter: 'invert(0)', onComplete: () => {
                                gsap.to(logoRef.current, { duration: 0.3, x: 0 });
                            }});
                    }});
            }
        }
    };

    useEffect(() => {
        const handleScroll = (event: Event) => {
            if (open) {
                event.preventDefault();
            }
        };

        if (open) {
            document.body.style.overflow = 'hidden';
            window.addEventListener('scroll', handleScroll, { passive: false });
        } else {
            document.body.style.overflow = 'visible';
            window.removeEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [open]);

    const scrollToSection = (id: string) => {
        const section = document.getElementById(id);
        if (section && window.innerWidth <= 1250) {
            setOpen(false);
            gsap.to(ulRef.current, { duration: 0.3, right: '-100%' });
            gsap.to(logoRef.current, { duration: 0.3, x: '-500%', onComplete: () => {
                    gsap.to(logoRef.current, { duration: 0.1, filter: 'invert(100%)', onComplete: () => {
                            gsap.to(logoRef.current, { duration: 0.3, x: 0, onComplete: () => {
                                    section.scrollIntoView({ behavior: 'smooth' });
                            }});
                    }});
            }});
        }
        else if(section && window.innerWidth > 1250) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <HeaderContainer>
            <ImageSlider/>
            <Wrapper>
                <Container>
                    <Navbar>
                        <Logo src={LogoImage} alt={"Amurg.cz logo"} ref={logoRef}/>
                        <Hamburger onClick={toggleMenu}>
                            <HamburgerIcon open={open} />
                            <HamburgerIcon open={open} />
                            <HamburgerIcon open={open} />
                        </Hamburger>
                        <Ul open={open} ref={ulRef}>
                            <Li>
                                <Link to="#o-nas" onClick={() => scrollToSection('o-nas')}>O nás</Link>
                            </Li>
                            <Li>
                                <Link to="#partneri" onClick={() => scrollToSection('partneri')}>Partneři</Link>
                            </Li>
                            <Li>
                                <Link to="#nas-tym" onClick={() => scrollToSection('nas-tym')}>Náš tým</Link>
                            </Li>
                            <Li>
                                <Link to="#stavba" onClick={() => scrollToSection('stavba')}>Stavba</Link>
                            </Li>
                            <Li>
                                <Link to="#web-development" onClick={() => scrollToSection('web-development')}>Web development</Link>
                            </Li>
                            <Li>
                                <Link to="#galerie" onClick={() => scrollToSection('galerie')}>Galerie</Link>
                            </Li>
                            <Li>
                                <Link to="#kontakt" onClick={() => scrollToSection('kontakt')}>Kontakt</Link>
                            </Li>
                        </Ul>
                    </Navbar>
                    <TextBox>
                        <Paragraph>JSME</Paragraph>
                        <Heading>AMURG<span style={{color: "#C7C8CC"}}>C</span>Z</Heading>
                        <SubHeading>DODAVATEL STAVBY A WEB DEVELOPMENTU</SubHeading>
                        <Row>
                            <TextBoxLink to="#galerie" onClick={() => scrollToSection('galerie')}>Prozkoumejte naše
                                projekty</TextBoxLink>
                            <TextBoxLink to="#kontakt" onClick={() => scrollToSection('kontakt')}>Spojte se s
                                námi</TextBoxLink>
                            <span style={{  marginTop: '20px', marginBottom: '20px' }}>Zde můžete zjistit více o našich projektech<br/> a životních zkušeností.</span>
                        </Row>
                    </TextBox>
                    <SocialIcons>
                        <RouterLink to="https://www.instagram.com/amurgcz/" target="_blank">
                            <SocialIcon>
                                <FontAwesomeIcon icon={faInstagram} color="#fff" size="2x"/>
                            </SocialIcon>
                        </RouterLink>
                    </SocialIcons>
                </Container>
            </Wrapper>
        </HeaderContainer>
    )
}
