import React, { useEffect, useRef } from 'react';
import { Box } from "@mui/material";
import gsap from "gsap";
import { useLogoAnimationContext } from '../context/LogoAnimationContext';
import { Loader } from "../components/Loader";
import { Header } from "../components/Header";
import { AboutUs } from "../components/AboutUs";
import { OurTeam } from "../components/OurTeam";
import { Construction } from "../components/Construction";
import { WebDevelopment } from "../components/WebDevelopment";
import { Projects } from "../components/Projects";
import { ContactUs } from "../components/ContactUs";
import { Footer } from "../components/Footer";
import { BackToTop } from "../components/BackToTop";
import { Partners } from '../components/Partners';

export const Home: React.FC = () => {
    const { animationEnded } = useLogoAnimationContext();
    const defaultPageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (animationEnded && defaultPageRef.current) {
            gsap.to(defaultPageRef.current, { opacity: 1, duration: 1 });
        }
    }, [animationEnded]);

    return (
        <>
            {!animationEnded ? (
                <Loader />
            ) : (
                <Box component="div" ref={defaultPageRef} sx={{ opacity: 0 }}>
                    <Header />
                    <AboutUs />
                    <Partners />
                    <OurTeam />
                    <Construction />
                    <WebDevelopment />
                    <Projects />
                    <ContactUs />
                    <Footer />
                    <BackToTop />
                </Box>
            )}
        </>
    )
}
