import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLogoAnimationContext } from '../context/LogoAnimationContext';
import LoadingAnimationGIF from '../images/loading-animation.gif';
import { gsap } from 'gsap';

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #030303;
  opacity: 1;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 0;
`;

export const Loader: React.FC = () => {
    const imageRef = useRef<HTMLImageElement>(null);
    const loaderRef = useRef<HTMLDivElement>(null);
    const { setAnimationEnded } = useLogoAnimationContext();

    useEffect(() => {
        const handleAnimation = () => {
            if (imageRef.current && loaderRef.current) {
                gsap.to(imageRef.current, { opacity: 1, duration: 0.5 });
                gsap.to(loaderRef.current, { opacity: 1, duration: 4.5, onComplete: () => {
                        gsap.to(loaderRef.current, { opacity: 0, duration: 0.5, onComplete: () => {
                                setAnimationEnded(true);
                        }});
                }});
            }
        };

        handleAnimation();
    }, []);

    return (
        <LoaderContainer ref={loaderRef}>
            <Image src={LoadingAnimationGIF} alt="Loading Animation" ref={imageRef} />
        </LoaderContainer>
    );
};
