import React from 'react';
import { LogoAnimationProvider } from './context/LogoAnimationContext';
import { Routes } from "./routing/Routes";

function App () {
  return (
      <LogoAnimationProvider>
        <Routes />
      </LogoAnimationProvider>
  );
}

export default App;
