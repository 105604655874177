import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderImage1 from '../images/header-image.png';
import HeaderImage2 from '../images/header-image-1.png';
import HeaderImage3 from '../images/header-image-2.png';

interface SlideProps {
    image: string;
}

const SliderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const SliderContent = styled.div<{ translateValue: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${props => props.translateValue}%);
`;

const Slide = styled.div<SlideProps>`
  min-width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

export const ImageSlider = () => {
    const originalImages = [HeaderImage1, HeaderImage2, HeaderImage3];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [translateValue, setTranslateValue] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % originalImages.length);
        }, 8000);

        return () => clearInterval(interval);
    }, [originalImages.length]);

    useEffect(() => {
        setTranslateValue(-currentImageIndex * 100);
    }, [currentImageIndex]);

    return (
        <SliderContainer>
            <SliderContent translateValue={translateValue}>
                {originalImages.map((image, index) => (
                    <Slide key={index} image={image} />
                ))}
            </SliderContent>
        </SliderContainer>
    );
};
