import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import styled from 'styled-components';

interface ButtonProps {
    show: boolean;
}

const Button = styled.button<ButtonProps>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  color: #777777;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: opacity 0.4s;
  opacity: ${(props) => (props.show ? '1' : '0')};
  border: none;

  &:hover {
    color: #fff;
  }

  & > svg {
    width: 30px;
    height: 30px;
  }
`;

export const BackToTop: React.FC = () => {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Button show={showButton} onClick={scrollToTop}>
            <FaArrowUp />
        </Button>
    );
};