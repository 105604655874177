import React from 'react';
import { BrowserRouter as Router, Route, Routes as RoutesDOM } from "react-router-dom";
import { Home } from "../pages/Home";

export const Routes: React.FC = () => {
    return (
        <Router>
            <RoutesDOM>
                <Route path="/" element={<Home />} />
            </RoutesDOM>
        </Router>
    )
}