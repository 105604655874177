import React from 'react';
import styled from 'styled-components';
import { FiPhone, FiMail } from 'react-icons/fi';
import ContactUsImage from '../images/contact-us.jpg'

const Section = styled.section`
  user-select: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1095px) {
    height: 100%;
  }
`;

const Main = styled.section`
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1095px) {
    height: 100%;
  }
`;

const AllText = styled.div`
  width: 600px;
  max-width: 100%;
  padding: 0 10px;
`;

const Image = styled.img`
  width: 800px;
  max-width: 100%;
  height: auto;
  padding: 0 10px;

  @media (max-width: 1516px) {
    width: 600px;
  }

  @media (max-width: 1305px) {
    width: 400px;
  }

  @media (max-width: 1095px) {
    display: none;
  }
`;

const MobileImage = styled.img`
  width: 70%;
  height: auto;
  display: none;

  @media (max-width: 1095px) {
    display: block;
  }
`;

const MobileImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionName = styled.h4`
  font-family: 'Anton', sans-serif;
  font-size: 30px;
  color: #777777;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 15px;
`;

const SectionTitle = styled.h1`
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 400px;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;

const ContactName = styled.h3`
  font-size: 24px;
  margin-bottom: 5px;
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneIcon = styled(FiPhone)`
  margin-right: 5px;
`;

const MailIcon = styled(FiMail)`
  margin-right: 5px;
`;

const EmailLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const DescriptionText = styled.div`
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const ContactUs: React.FC = () => {
    return (
        <Section id={"kontakt"}>
            <Main>
                <AllText>
                    <SectionName>KONTAKT</SectionName>
                    <SectionTitle>Kontaktujte nás z pohodlí Vašeho domova</SectionTitle>
                    <Row>
                        <ContactItem>
                            <ContactName>Lukáš Gerhardt</ContactName>
                            <DescriptionText>CEO</DescriptionText>
                            <ContactInfo>
                                <PhoneIcon />
                                770 668 830
                            </ContactInfo>
                            <ContactInfo>
                                <MailIcon />
                                <EmailLink href="mailto:gerhardt@amurg.cz">gerhardt@amurg.cz</EmailLink>
                            </ContactInfo>
                        </ContactItem>
                    </Row>
                    <Row>
                        <ContactItem>
                            <ContactName>Filip Maršík</ContactName>
                            <DescriptionText>Stavbyvedoucí</DescriptionText>
                            <ContactInfo>
                                <PhoneIcon />
                                601 601 421
                            </ContactInfo>
                            <ContactInfo>
                                <MailIcon />
                                <EmailLink href="mailto:stavbyvedouci@amurg.cz">stavbyvedouci@amurg.cz</EmailLink>
                            </ContactInfo>
                        </ContactItem>
                    </Row>
                    <Row>
                        <ContactItem>
                            <ContactName>Robin Bezák</ContactName>
                            <DescriptionText>Web Development</DescriptionText>
                            <ContactInfo>
                                <MailIcon />
                                <EmailLink href="mailto:it@amurg.cz">it@amurg.cz</EmailLink>
                            </ContactInfo>
                        </ContactItem>
                    </Row>
                    <Row>
                        <ContactItem>
                            <ContactName>Amurg CZ, s.r.o.</ContactName>
                            <ContactInfo>
                                IČO: 26210771
                            </ContactInfo>
                            <ContactInfo>
                                DIČ: CZ26210771
                            </ContactInfo>
                            <ContactInfo>
                                Wolkerova 2766, Kročehlavy, 272 01 Kladno
                            </ContactInfo>
                        </ContactItem>
                    </Row>
                </AllText>
                <Image src={ContactUsImage} alt="Obrázek k sekci kontakt" />
                <MobileImageWrapper>
                    <MobileImage src={ContactUsImage} alt="Obrázek k sekci kontakt"/>
                </MobileImageWrapper>
            </Main>
        </Section>
    );
};
