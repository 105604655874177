import React from 'react';
import styled from 'styled-components';
import LogoImage from '../images/logo.svg';
import { Link as RouterLink } from "react-router-dom";

const FooterSection = styled.footer`
  background-color: #000;
  color: #fff;
  padding: 2% 8%;
  user-select: none;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  margin-top: 15px;
  width: 80px;
  cursor: pointer;
  filter: invert(1);
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const SiteMap = styled.nav`
  flex: 1;
  text-align: center;
`;

const SiteMapList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex; /* Changed display property to flex */
  flex-direction: row; /* Set flex-direction to row */
  justify-content: center; /* Center align the items horizontally */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SiteMapItem = styled.li`
  margin-bottom: 5px; /* Reduced margin between sitemap items */
  margin-right: 20px; /* Added margin to separate sitemap items */

  @media (max-width: 768px) {
    margin-right: 0;
    margin-top: 20px;
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Link = styled(RouterLink)`
  color: #fff;
  text-decoration: none;
  position: relative;
  font-family: 'Anton', sans-serif;
  letter-spacing: 2px;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  &::after {
    content: '';
    width: 0;
    height: 3px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    transition: width 0.3s;
  }
  &:hover::after {
    width: 50%;
  }
`;

const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const Footer = () => {
    return (
        <FooterSection>
            <FooterContainer>
                <Logo src={LogoImage} alt="Logo Amurg.cz" onClick={() => scrollToTop()} />
                <SiteMap>
                    <SiteMapList>
                        <SiteMapItem><Link to="#o-nas" onClick={() => scrollToSection('o-nas')}>O nás</Link></SiteMapItem>
                        <SiteMapItem><Link to="#partneri" onClick={() => scrollToSection('partneri')}>Partneři</Link></SiteMapItem>
                        <SiteMapItem><Link to="#nas-tym" onClick={() => scrollToSection('nas-tym')}>Náš tým</Link></SiteMapItem>
                        <SiteMapItem><Link to="#stavba" onClick={() => scrollToSection('stavba')}>Stavba</Link></SiteMapItem>
                        <SiteMapItem><Link to="#web-development" onClick={() => scrollToSection('web-development')}>Web development</Link></SiteMapItem>
                        <SiteMapItem><Link to="#galerie" onClick={() => scrollToSection('galerie')}>Projekty</Link></SiteMapItem>
                        <SiteMapItem><Link to="#kontakt" onClick={() => scrollToSection('kontakt')}>Kontakt</Link></SiteMapItem>
                    </SiteMapList>
                </SiteMap>
            </FooterContainer>
            <Copyright>
                &copy; {new Date().getFullYear()} AMURG.CZ
            </Copyright>
        </FooterSection>
    );
};
